import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Settings } from "../../config";

export const initializeTrustPilot = () => {
  var script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
  script.async = true;
  document.head.appendChild(script);
  script.onload = function () {
    var trustbox = document.getElementById("trustbox");
    if (window.Trustpilot) window.Trustpilot.loadFromElement(trustbox);
  };
};

const TrustPilotWidget = ({ isMobile }) => {
  const ref = useRef(null);

  useEffect(() => initializeTrustPilot(), []);

  return (
    <div
      ref={ ref }
      className="trustpilot-widget align-self-center"
      data-locale="en-US"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id={ Settings.TRUSTPILOT_BUSINESS_UNIT_ID }
      data-style-height={ isMobile ? "27px" : "20px" }
      data-style-width="fit-content"
      data-theme="light"
      data-font-family="Ubuntu"
    >
      <a
        href={ Settings.TRUSTPILOT_URL }
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};

TrustPilotWidget.propTypes = {
  isMobile: PropTypes.bool,
};

export default TrustPilotWidget;
