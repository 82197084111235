import { Settings } from "../config";
import {
  FetchStatus,
  LanderCookie,
  FeedProvider,
  EventType,
  ErrorCode,
  System,
} from "../commons/Constants";
import {
  getCookie,
  objectToQueryStringExcludeNull,
} from "../commons/HelperFunctions";
import Logger from "../commons/Logger";
import postEvent, { createBaseLanderEvent } from "../commons/LanderEvent";

const SUCCESS_MESSAGE = "success";

export function getVerizonRSUri(rootDomain, keywords, system) {
  let partner = "";

  if (system === System.CASHPARKING) {
    partner = Settings.VERIZON_FEED_PARTNER.CP;
  } else if (system === System.PARKWEB) {
    partner = Settings.VERIZON_FEED_PARTNER.PW;
  } else if (system === System.SMARTNAME) {
    partner = Settings.VERIZON_FEED_PARTNER.SN;
  }

  // if test partner is set, override other config
  if (Settings.VERIZON_FEED_PARTNER.TEST)
    partner = Settings.VERIZON_FEED_PARTNER.TEST;

  const rsParams = {
    url: rootDomain,
    Partner: partner,
    maxListings: 3,
    maxWeb: 0,
    maxRT: 6,
    maxRTL: 6,
    maxPC: 6,
    urlLang: "en",
    affilData: {
      ip: getCookie(LanderCookie.CLIENT_IP),
      xfip: getCookie(LanderCookie.CLIENT_IP),
      ua: navigator.userAgent,
      ur: window.location.href.replace(window.location.search, ""), // Strip query params
    },
    serveUrl: `${window.location.protocol}//${rootDomain}`,
  };
  if (keywords) rsParams.seedTerm = keywords.join(" ");
  return `?${objectToQueryStringExcludeNull(rsParams)}`;
}

export function getVerizonAdsUri(qs, rootDomain, searchQuery) {
  const adsParam = {
    verizon: 1, // This param ensure that our lander will show Verizon instead of Google ads
    serveUrl: `${window.location.protocol}//${rootDomain}`,
  };
  if (searchQuery) adsParam.q = searchQuery;
  const adsParamStr = objectToQueryStringExcludeNull(adsParam);

  // Get url params
  const newURL = new URLSearchParams(window.location.search);
  // Ensure we don't have duplicate url params
  newURL.delete("qs");
  newURL.delete("q");
  newURL.delete("serveURL");
  newURL.delete("verizon");

  return (
    window.location.href.replace(window.location.search, "") +
    `?${newURL.toString()}&${adsParamStr}&${qs}`
  );
}

export async function verizonXmlFetch(urlParams) {
  try {
    const response = await fetch(`${Settings.VERIZON_FEED_PATH}${urlParams}`, {
      method: "GET",
      credentials: "omit",
    });

    if (response.status !== 200)
      throw new Error(`got ${response.status} from verizon`);

    const txt = await response.text();
    const xml = new DOMParser().parseFromString(txt, "text/xml");
    if (xml.querySelector("parsererror"))
      throw new Error("xml failed to parse");

    // Check for errors from Verizon Feed
    Array.from(xml.getElementsByTagName("StatusItem")).forEach((statusItem) => {
      const status = parseInt(statusItem.getAttribute("code"), 10);
      if (status >= 400)
        throw new Error(
          `${status} response from verizon feed: ${statusItem.textContent}`
        );
    });

    return {
      fetchStatus: FetchStatus.SUCCESS,
      xmlResult: xml,
      message: SUCCESS_MESSAGE,
    };
  } catch (err) {
    Logger.error(err);
    return {
      fetchStatus: FetchStatus.FAILURE,
      message: err.message,
    };
  }
}

export function sendVerizonEvent(message, isAdClicked, landerConfig) {
  const verizonEvent = createBaseLanderEvent(null, landerConfig);
  verizonEvent.feedProvider = FeedProvider.VERIZON;
  verizonEvent.verizonApiMessage = message;

  if (message !== SUCCESS_MESSAGE) {
    verizonEvent.eventType = EventType.ERROR;
    verizonEvent.errorCode = isAdClicked
      ? ErrorCode.ERROR_VERIZON_NO_ADS
      : ErrorCode.ERROR_VERIZON_NO_RS;
  } else {
    verizonEvent.eventType = isAdClicked
      ? EventType.VERIZON_AD_CLICKED
      : EventType.VERIZON_VISIT;
  }

  postEvent(Settings.EVENT_PUBLISH_API, verizonEvent);
}
