import { BannerType } from "../banner/Banner";
import { Experiment, LanderConfig } from "../types/LanderConfig";

export function getExperiment(landerConfigBody: any): Experiment {
  const { experiment } = landerConfigBody;

  if (!experiment) {
    return {};
  }

  // Limit experiment based on target Customer Ids
  if (
    experiment.data?.targetCustomerIds &&
    Array.isArray(experiment.data.targetCustomerIds) &&
    !experiment.data.targetCustomerIds.includes(landerConfigBody.customerId)
  ) {
    return {};
  }

  return {
    key: experiment.experiment,
    cohort: experiment.cohort,
    data: experiment.data,
  };
}

export const getTypeID = (type: string) => {
  if (type === BannerType.AUCTION) return "auctions";
  if (type === BannerType.DBS) return "broker";
  return "other";
};

export const getChannelID = (
  inTreatmentCohort: boolean,
  landerConfig: LanderConfig
) => {
  return inTreatmentCohort
    ? landerConfig.adSense.channel + "+treatment"
    : landerConfig.adSense.channel + "+control";
};

export const getExperimentBannerLink = (
  inTreatmentCohort: boolean,
  landerConfig: LanderConfig
) => {
  const type = landerConfig?.lander?.banner?.type ?? "";
  const url = new URL(landerConfig?.lander?.banner?.link ?? "");
  const { utmCampaignAuction, utmCampaignDBS, utmCampaignOther } =
    landerConfig?.experiment?.data || {};

  // Allow experiment data to override the campaign ID
  let campaignID = "";
  if (type === BannerType.AUCTION && utmCampaignAuction) {
    campaignID = utmCampaignAuction;
  } else if (type === BannerType.DBS && utmCampaignDBS) {
    campaignID = utmCampaignDBS;
  } else if (utmCampaignOther) {
    campaignID = utmCampaignOther;
  } else {
    const cohortID = inTreatmentCohort ? "b" : "a";
    campaignID = `x_dom-${getTypeID(
      type
    )}_parkedpages_x_x_invest_${cohortID}_001`;
  }

  url.searchParams.set("utm_campaign", campaignID);
  return decodeURIComponent(url.href);
};
