/**
 * Commonly used constants.
 * Keep this file organized and small.
 *
 * FIXME: This file is getting too big.
 * Need to slowly move constants into "constants" folder to deprecate this file.
 */

// API request status
export const FetchStatus = {
  PENDING: 1, // API call has not being initiated or is in-progress
  SUCCESS: 2, // API call is completed and was successful
  FAILURE: 3, // API call is completed but encountered an error
};

/**
 * Different display type (Ads, for-sale page)
 */
export const DisplayType = {
  ADS: "ADS",
  FOR_SALE: "FOR_SALE",
};

/**
 * Domain Status
 */
export const DomainStatus = {
  ACTIVE: "ACTIVE",
  BLACKLISTED: "BLACKLISTED",
  SUSPENDED_FRAUD: "SUSPENDED_FRAUD",
  SUSPENDED_TRADEMARK: "SUSPENDED_TRADEMARK",
};

/*
 * Events on Parking Lander application
 */
export const EventType = {
  AD_CLICK: "AD_CLICK",
  BANNER_CLICK: "BANNER_CLICK",
  ERROR: "ERROR",
  KEYWORD_CLICK: "KEYWORD_CLICK",
  KEYWORD_SEARCH: "KEYWORD_SEARCH",
  REDIRECT_FOS: "REDIRECT_FOS",
  VISIT: "VISIT",
  VERIZON_VISIT: "VERIZON_VISIT", // Verizon RS successfully fetched
  VERIZON_AD_CLICKED: "VERIZON_AD_CLICKED", // Verizon ads successfully fetched
  SERP_REDIRECT: "SERP_REDIRECT",
  SERP_REDIRECT_CONSENT: "SERP_REDIRECT_CONSENT",
};

export const FeedProvider = {
  GOOGLE_AD_SENSE: "GOOGLE_AD_SENSE",
  VERIZON: "VERIZON",
};

/**
 * Supported query parameters (user-level).
 * All query param keys are lower-cased while parsing
 */
export const QueryParam = {
  DOMAIN: "domain", // string: can be use to specify domain name
  S_TOKEN: "s_token", // string: tracking code
  UUID: "uuid", // UUID sent by the caller
  TEMPLATE: "template", // string: template to render
  SEARCH_BOX: "searchbox", // boolean: Adsense search box show/hide
  SEARCH_BUTTON: "searchbutton", // boolean: Adsense search bottom  show/hide
  SHOW_DOMAIN: "showdomain", // boolean: show/hide
  TDFS: "tdfs", // boolean: show/hide
  BACK_FILL: "backfill", // boolean: Adsense backfill ads
  TERM: "term", // array: Adsense related search terms
  DEBUG_MODE: "debugmode", // boolean: If true then Logger severity is set to TRACE,
  VERIZON_FEED: "verizon",
};

/*
 * Application error codes
 */
export const ErrorCode = {
  ERROR_FETCHING_DOMAIN_CONFIG: "ERROR_FETCHING_DOMAIN_CONFIG",
  ERROR_DOMAIN_STATUS_INVALID: "ERROR_DOMAIN_STATUS_INVALID",
  ERROR_ADSENSE_NO_CAF: "ERROR_ADSENSE_NO_CAF", // caf library not found in global
  ERROR_ADSENSE_CAF: "ERROR_ADSENSE_CAF", // used when page-load fails
  ERROR_ADSENSE_NO_ADS: "ERROR_ADSENSE_NO_ADS",
  ERROR_ADSENSE_NO_RS: "ERROR_ADSENSE_NO_RS",
  ERROR_UNKNOWN_DOMAIN_TYPE: "ERROR_UNKNOWN_DOMAIN_TYPE",
  ERROR_NO_DRID: "ERROR_NO_DRID",
  ERROR_NO_PUBID: "ERROR_NO_PUBID",
  ERROR_VERIZON_NO_ADS: "ERROR_VERIZON_NO_ADS",
  ERROR_VERIZON_NO_RS: "ERROR_VERIZON_NO_RS",
};

export const LanderCookie = {
  CITY: "city",
  CLIENT_IP: "caf_ipaddr",
  COUNTRY: "country",
  EXPIRY_PARTNER: "expiry_partner",
  SYSTEM: "system",
  TRAFFIC_TARGET: "traffic_target",
  VISITOR_ID: "pvisitor",
  GCP_LB_IP: "gcp_lb_ip",
};

export const System = {
  SMARTNAME: "SN",
  CASHPARKING: "CP",
  PARKWEB: "PW",
};

export const Locale = {
  EN_US: "en",
};

export const TDFSCookie = {
  // This cookie is set in tdfs-api. The value comes from hivemind
  // https://github.com/gdcorp-domains/afternic-tdfs-api/blob/main/internal/tdfs-api/routes/lander/experiment.go#L26
  EXP: "tdfs_exp",
};
